$env-variable: 'dev';
.ruleModal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;

  .ruleField {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
    column-gap: 8px;
  }

  .lastField {
    grid-column-end: span 2;
  }
}
