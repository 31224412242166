$env-variable: 'dev';
@use '@common/style/var' as var;

.authPageContainer {
  display: grid;
  row-gap: 16px;
  width: 650px;
  margin-left: 24px;
}

.authUserPageContainer {
  display: grid;
  row-gap: 22px;
  position: relative;
}

.loadMoreWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.authHeaderContainer {
  display: flex;
  align-items: center;
  row-gap: 8px;
  column-gap: 8px;

  .item {
    color: var(--white)_80;
  }

  &__actions {
    display: flex;
    margin-left: auto;
    column-gap: 12px;
  }
}
