$env-variable: 'dev';
@use '@common/style/var' as var;

.titleCardContainer {
  height: 50%;
  padding: 10px 0 10px 0;
  margin: 0 30px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
  position: relative;
  display: flex;
  align-items: center;
}

.projectNameContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;

  div {
    width: 25px;
    height: 25px;
    line-height: 28px;
  }

  img {
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
}

.nameProject {
  font-size: 18px;
  margin-left: 10px;
  overflow: hidden;
  white-space: nowrap;
  color: var(--white);

  .codeProject {
    color: #b3b9c3;
    font-size: 14px;
  }
}
