@mixin typography {
  font-weight: 300;

  h1 {
    font-size: 28px;
    margin: 0;
  }

  h6 {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  h5 {
    font-size: 16px;
    font-weight: lighter;
    margin: 0;
  }
}
