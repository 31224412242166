$env-variable: 'dev';
.tableContainer {
  .toolsContainer {
    display: flex;
    align-items: center;
    column-gap: 16px;
    justify-content: flex-end;

    .selector {
      width: 251px;
    }

    span {
      visibility: hidden;
      position: absolute;
    }
  }
}
