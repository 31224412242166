$env-variable: 'dev';
@use '@common/style/var' as var;
@use '@common/style/typography' as *;

.modalOverlay {
  top: 0;
  left: 0;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;

  .styledModal {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    @include typography;
  }

  .modalTitle {
    position: relative;
    color: var(--white);
    font-weight: 700;
    width: 100%;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modalActions {
    display: flex;
    flex-direction: row;
    justify-content: end;
    column-gap: 12px;
  }

  .modalBody {
    display: flex;
    justify-content: center;
    padding: 24px 0;
    line-height: 140%;
    color: var(--white)_50;

    form {
      width: 100%;
      display: grid;
      row-gap: 8px;

      section {
        position: relative;
        font-size: 12px;
        display: grid;
        row-gap: 4px;
        color: var(--white)_80;
      }
    }
  }
}

.modalText {
  line-height: 24px;
}
