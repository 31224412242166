$env-variable: 'dev';
.authUserContainer {
  display: grid;
  column-gap: 12px;
  row-gap: 16px;
  grid-template-columns: auto auto;
}

.deleteContainer {
  margin-left: auto;
}

.switcher {
  display: flex;
  align-items: center;
  column-gap: 16px;

  .labelSwitcher {
    font-size: 12px;
  }
}
