$env-variable: 'dev';
@use '@common/style/var' as var;

%fieldInputContainer {
  width: 100%;
  margin-bottom: 30px;
}

.fieldInputContainer {
  @extend %fieldInputContainer;
}

.configContainer {
  margin-top: 28px;
}

.twoFieldContainer {
  @extend %fieldInputContainer;
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-bottom: 0;

  input {
    margin-bottom: 30px;
  }
}

.settingsContainer {
  color: var(--white)_80;

  .content__container {
    margin-top: 40px;
  }

  .iconContainer {
    display: flex;
    column-gap: 20px;
    align-items: center;
  }

  .uploadButtonContainer {
    align-items: center;
    display: flex;
    white-space: nowrap;

    input {
      visibility: hidden;
    }

    label {
      cursor: pointer;
      color: var(--highlightColorDefault);
      font-size: 16px;

      &:hover {
        color: var(--highlightColorHover);
      }

      &:active {
        color: var(--highlightColorActive);
      }
    }
  }

  .iconFile {
    width: 32px;
    height: 32px;
    border-radius: 32px;
  }

  .textContainer {
    color: var(--white);
  }

  .singleButtonContainer {
    width: 172px;
  }

  .buttonContainer {
    display: flex;
    width: 360px;
    column-gap: 32px;
  }

  form {
    max-width: 586px;
    display: flex;
    flex-direction: column;
    row-gap: 44px;
  }

  .fieldsContainer {
    display: grid;
    row-gap: 28px;
    grid-template-columns: 190px auto;
    align-items: center;
    width: 100%;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.fieldValue {
}

.tabContainer {
  display: grid;
  row-gap: 20px;

  td {
    .flexTd {
      align-items: center;

      .form {
        margin: 0;
        width: 300px;

        input {
          padding: 5px 15px;
          height: auto;
        }

        .label {
          left: 15px;
          right: 15px;
        }
      }
    }
  }
}

.tableButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
}

.titlePage {
  margin: 0;
  padding: 0;
  font-size: 36px;
  color: var(--white);
}

.generalContainer {
  margin-top: 30px;
  max-width: 800px;
  padding-bottom: 20px;
  color: var(--white);

  .fieldContainer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 14px;

    .nameFieldContainer {
      .nameField {
        font-weight: 700;
        width: 152px;
      }
    }

    .fieldInputContainer {
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .buttonContainer {
    padding-left: 152px;
  }
}

.fieldSelect {
  border-width: 1px;
  border-style: solid;
  border-color: hsla(0, 0%, 89.8%, 0.3);
  width: 100%;
  background: #3e4651;
  border-radius: 4px;
  font-size: 16px;
  outline: unset;
  color: var(--white);
  padding: 15px 35px 15px 16px;
  -webkit-appearance: none;
  appearance: none;
}

.selectWrapper {
  position: relative;

  &:after {
    content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6036 15.6941L12.6751 16.5699L12.3742 16.8538C12.1675 17.0487 11.8325 17.0487 11.6258 16.8538L3.82165 9.49217C3.615 9.29724 3.615 8.9812 3.82165 8.78626L4.6769 7.97951C4.88355 7.78458 5.2186 7.78458 5.42525 7.97951L12 14.1814L18.5747 7.97951C18.7814 7.78458 19.1164 7.78458 19.3231 7.97951L20.1783 8.78626C20.385 8.98119 20.385 9.29724 20.1783 9.49217L13.6036 15.6941Z' fill='white' fill-opacity='0.5'/%3E%3C/svg%3E%0A");
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    right: 12px;
  }
}
