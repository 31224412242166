$env-variable: 'dev';
@use '@common/style/var.scss' as var;

.nameInput {
  position: absolute;
  font-size: 12px;
  top: -17px;
}

.underPageContainer {
  display: grid;
  row-gap: 22px;
  position: relative;
  margin-left: 24px;
}

.inputContainer {
  position: relative;
}

.popupContextContainer {
  display: grid;
  grid-auto-flow: row;
  row-gap: 16px;
  justify-items: center;
}

.errorMessageContainer {
  color: var(--errorColor);
  font-size: 12px;
  padding-top: 5px;
}

.popupContainer {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
}

.buttonContainer {
  display: flex;
  align-items: center;
}

.pageWrapper {
  padding: 0 20px;
  display: grid;
  row-gap: 16px;
}

.underTabsContainer {
  display: flex;
  align-items: center;
  column-gap: 6px;

  .underLink {
    cursor: pointer;
    user-select: none;
    font-size: 14px;
    border-radius: 8px;
    color: var(--white)_50;
    padding: 8px 12px;

    &:hover {
      color: var(--white);
      background-color: var(--tableColor);
    }

    &.activeUnderLink {
      color: var(--white);
      background-color: var(--tableColor);
    }
  }
}

.mainPageContainer {
  padding: 0 32px 24px;
  display: grid;
  row-gap: 24px;
}

.tabsContainer {
  display: flex;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 24px;
  column-gap: 24px;

  .link {
    font-size: 16px;
    padding-bottom: 8px;
    cursor: pointer;
    box-sizing: border-box;
    border-bottom: 3px solid transparent;
    color: var(--white)_50;

    &:hover {
      color: var(--white);
      border-bottom: 3px solid var(--highlightColorDefault);
    }

    &.activeLink {
      color: var(--white);
      border-bottom: 3px solid var(--highlightColorDefault);
    }
  }
}

.titlePageContainer {
  display: grid;
  font-size: 40px;
  font-weight: bold;
  align-items: center;
  row-gap: 12px;
}

.plus {
  font-size: 28px;
  line-height: 1%;
  display: flex;
  align-items: center;

  &:after {
    line-height: 1%;
    content: '+';
  }
}

.minus {
  height: 15px;
  line-height: 1%;
  font-size: 28px;

  &:after {
    line-height: 1%;
    content: '-';
  }
}

.title {
  font-size: 32px;
  font-weight: bold;
}

.descriptionContainer {
  margin-top: 16px;
  width: 504px;
}

.description {
  color: #b2b9c4;
  font-size: 16px;
  line-height: 148%;
}

.buttonPlanContainer {
  justify-content: center;
  display: flex;
}
