$env-variable: 'dev';
@use '@common/style/var' as var;

.errorPageContainer {
  background: var(--mainBackgroundColor);
  height: 100vh;
  position: relative;

  .worldContainer {
    justify-content: end;
    display: flex;
    margin-left: 100px;
    max-height: 100vh;
    overflow: hidden;

    svg {
      height: 100%;
      width: 75%;
    }
  }

  .formContainer {
    position: absolute;
    top: 40%;
    left: 20%;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    row-gap: 60px;
  }

  .text {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .title {
      font-size: 40px;
      font-weight: bold;
    }

    .description {
      opacity: 80%;
      font-size: 16px;
    }
  }
}
