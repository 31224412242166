@import '../../../node_modules/rsuite/DateRangePicker/styles/index.css';

.rs-input {
  font-family: Gordita, serif;
  font-size: 16px;
  font-weight: 400;
  outline: 0;
  line-height: 22px;
  border-radius: 8px;
  width: 100%;
  padding: 12px;
  background-color: #303340;
  color: #ffffff;
  border: unset;
}

.rs-picker-popup {
  background-color: #1a1c23;
}

.rs-input-group.rs-input-group-inside {
  background-color: #303340;
  border: unset;
}
