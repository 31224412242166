$env-variable: 'dev';
.objectPage {
  display: grid;
  row-gap: 24px;
}

.objectContainer {
  position: relative;
  margin: 24px 0;
  display: grid;
  row-gap: 24px;

  &__content {
    display: grid;
    row-gap: 16px;
    width: 60%;
    min-width: 600px;
  }

  &__header {
    column-gap: 12px;
    position: absolute;
    display: flex;
    right: 0;
    justify-content: end;
    top: -55px;
  }
}
