$env-variable: 'dev';
@use '@common/style/var.scss' as var;

%button {
  width: max-content;
  padding: 8px 20px;
  border-radius: 4px;
  color: var(--white);
  outline: unset;
  user-select: none;
  border: unset;
  box-sizing: border-box;
}

.selectorBlock {
  display: flex;
  column-gap: 40px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.poolButtonsBlock {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.buttonSave {
  @extend %button;
  background-color: var(--highlightColorDefault);

  :disabled {
    opacity: 0.5;
  }
}

.buttonRefresh {
  @extend %button;
  background-color: var(--mediumGrey);
  display: flex;
}

.serverPoolTitle {
  font-size: 18px;
  font-weight: bold;
}

.selectorBlockContainer {
  display: flex;
  align-items: end;
  justify-content: space-between;

  .poolsBlock {
    display: grid;
    justify-content: space-between;
    align-items: center;
    row-gap: 4px;
  }

  .poolName {
    letter-spacing: 0.01em;
    font-size: 12px;
    color: var(--white)_80;
    min-width: 198px;
  }
}

.poolBlock {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
