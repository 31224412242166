$env-variable: 'dev';
.poolField {
  display: flex;
  justify-content: space-between;
  column-gap: 8px;

  section {
    width: 100%;
  }
}
