$env-variable: 'dev';
.targetIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  height: 24px;
  width: 24px;
  opacity: 0.4;

  svg {
    width: 16px;
    height: 16px;
  }

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    opacity: 1;
    background-color: #1074d029;
  }

  &:active {
    opacity: 1;
    background-color: #1074d03d;
  }

  &:focus {
    opacity: 1;
  }
}
