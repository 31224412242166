$env-variable: 'dev';
@use '@common/style/var.scss' as var;

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: -5px;
    bottom: 0;
    border: 1px solid rgba(255, 255, 255, 0.6);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: '';
      height: 10px;
      width: 10px;
      left: 5px;
      bottom: 4px;
      opacity: 1;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  .checkBox {
    opacity: 0;
    width: 0;
    height: 0;

    &:disabled + .slider {
      opacity: 0.2;
    }

    &:checked + .slider {
      background-color: var(--highlightColorDefault);
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    &:focus {
      box-shadow: 0 0 1px var(--highlightColorDefault);
    }
  }
}
