$env-variable: 'dev';
@use '@common/style/var' as var;

.container {
  width: 350px;
}

.character {
  box-sizing: border-box;
  border: 0;
  color: var(--white);
  font-weight: 400;
  background-image: none;
  background-color: var(--tableBackgroundColor);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-align: center;
  width: 56px;
  height: 48px;
  border-radius: 16px;
  font-size: 16px;

  &.error {
    border: 1px solid var(--errorColor);
  }

  &__selected {
    outline-color: var(--highlightColorDefault);
  }

  &__filled {
    font-size: 16px;
  }

  &__inactive {
    color: var(--placeholderColor);
    width: 56px;
  }
}

.codeContainer {
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: start;
  width: 100%;
}
