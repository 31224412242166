:root {
  /* Colors */
  --white: #ffffff;
  --lightGrey: #5b5f64;
  --green: #27ae60;
  --mediumGrey: #68717e;
  --tableColor: #1a1c2399;
  --nightShade: #1a1c23;
  --placeholderColor: rgb(117, 117, 117);

  --lightBg: #1a1c23;
  --errorColor: #ff0001;
  --tooltipBackground: #252832;
  --notificationErrorBackground: #303340;
  --notificationErrorIconBackground: #3d4152;

  /* Main background color */
  --mainBackgroundColor: #0a0b10;
  --commonBackgroundColor: #1a1c23;

  --boxShadow: #0a0b10cc 0px 25px 50px -12px;

  /* White color with opacity */
  --white_80: #ffffffcc;
  --white_50: #ffffff80;
  --white_30: #ffffff4d;
  --white_20: #ffffff33;

  /* Table color */
  --tableBackgroundColor: #303340;

  /* Highlight color */
  --highlightColorDefault: #2e8de5;
  --highlightColorActive: #065fb2;
  --highlightColorHover: #1074d0;

  /* Color for chevron */
  --arrowColorDefault: invert(38%) sepia(7%) saturate(342%) hue-rotate(173deg) brightness(93%) contrast(93%);
  --arrowColorActive: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);

  /* Highlight color for img */
  --highlightImgColorDefault: invert(45%) sepia(81%) saturate(1860%) hue-rotate(189deg) brightness(95%) contrast(88%);
  --highlightImgColorActive: invert(23%) sepia(71%) saturate(1956%) hue-rotate(192deg) brightness(103%) contrast(96%);
  --highlightImgColorHover: invert(29%) sepia(100%) saturate(1176%) hue-rotate(187deg) brightness(99%) contrast(93%);

  /* Input text background */
  --inputTextDefaultBgColor: #303340;
  --inputTextHoverBgColor: #3d4152;
  --inputTextDisabledBgColor: #1a1c23;
  --inputTextErrorBgColor: #ed405526;

  /* Input text color */
  --inputTextPlaceholderDefault: var(--white_20);
  --inputTextPlaceholderHover: var(--white_50);
  --inputTextPlaceholderActive: var(--white);

  /* Input select */
  --inputSelectDefault: #303340;
  --inputSelectHover: #1a1c23;
  --inputSelectError: #1a1c23;
  --inputSelectDisabled: #1a1c23;

  /* Ghost button */
  --ghostButtonDefault: transparent;
  --ghostButtonHover: #1a1c23;
  --ghostButtonActive: #252832;
  --ghostButtonDisabled: #1a1c23;
}
