$env-variable: 'dev';
@use '@common/style/var' as var;

.bypassContainer {
  position: relative;
  flex-direction: column;
  display: flex;
  margin-right: 10px;

  td {
    .flexTd {
      height: 30px;
      column-gap: 30px;
      align-items: center;

      .form {
        margin: 0;
        width: 300px;

        input {
          padding: 5px 15px;
          height: auto;
        }

        .label {
          left: 15px;
          right: 15px;
        }
      }
    }
  }
}

.inputContainer {
  display: flex;
  width: 70%;
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 20px;

  .error {
    color: var(--errorColor);
    padding: 0 10px;
  }
}
