$env-variable: 'dev';
@use '@common/style/var' as var;

.textFieldContainer {
  width: 100%;
  display: flex;
  position: relative;

  .inputIcon {
    position: absolute;
    display: flex;
    height: 100%;
    justify-content: end;
    align-items: center;
    right: 12px;
  }

  .textErrorContainer {
    color: var(--errorColor);
    font-size: 11px;
    position: absolute;
    right: 0;
    bottom: -16px;
  }

  .resizable {
    resize: vertical;
  }
}
