$env-variable: 'dev';
@use '@common/style/var' as var;

.imgContainer {
  background: var(--highlightColorDefault);
  line-height: 35px;
  border-radius: 400px;
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  color: var(--white);
}

.projectIcon {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
