$env-variable: 'dev';
@use '@common/style/var' as var;

.menuContainer {
  background: var(--lightBg);
  box-shadow: var(--boxShadow);
  color: var(--white);
  position: absolute;
  top: 40px;
  box-sizing: border-box;
  row-gap: 5px;
  display: grid;
  z-index: 1;
  border-radius: 4px;
  font-size: 14px;
  width: max-content;
  right: 20px;
}

.menuItems {
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
  line-height: 24px;
  margin: 12px;
  width: 149px;

  .localesItems {
    display: grid;
    font-size: 12px;
    padding-left: 12px;
  }

  .localesItem {
    color: var(--white);

    &:hover {
      color: var(--highlightColorDefault);
    }

    &:active {
      color: var(--highlightColorActive);
    }
  }

  &:hover {
    color: var(--highlightColorDefault);
    background-color: var(--ghostButtonHover);
  }

  &:active {
    color: var(--highlightColorActive);
    background-color: var(--ghostButtonActive);
  }
}
