$env-variable: 'dev';
@use '@common/style/var' as var;

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  opacity: 0.6;
  color: var(--white);
  font-weight: bold;
  padding: 116px 0;

  .imgContainer {
    margin: 100px 0 10px 0;
  }

  .text {
    font-weight: 400;
    font-size: 24px;
    color: var(--white)_80;
  }
}
