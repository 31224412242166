$env-variable: 'dev';
.dataRefreshContainer {
  display: flex;
  align-items: center;
  color: #b3b9c3;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}

.periodFormRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .buttonsContainer {
    display: flex;
    align-items: center;

    .buttonReset {
      color: rgba(255, 255, 255, 0.5);
      cursor: pointer;
    }
  }
}

.progressContainer {
  user-select: none;
  width: 100px;
  height: 20px;
  background: #878c95;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.graphContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  position: relative;
  justify-content: space-between;
}

.filterWrapper {
  display: flex;
  justify-content: start;
  align-items: center;
  column-gap: 12px;
}
