$env-variable: 'dev';
.supportCellContainer {
  display: flex;
  column-gap: 8px;
}

.icon {
  display: flex;
  align-items: center;
  column-gap: 4px;

  svg {
    width: 15px;
    height: 16px;
  }

  img {
    width: 15px;
    height: 16px;
  }
}
