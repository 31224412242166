$env-variable: 'dev';
@use '@common/style/var' as var;

.cellContainer {
  display: flex;
  flex-direction: column;
}

.clientSupportContainer {
  display: grid;
  font-size: 16px;
  row-gap: 8px;
  color: var(--white);
}
