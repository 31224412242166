$env-variable: 'dev';
.metadataContainer {
  display: grid;
  column-gap: 12px;
  row-gap: 16px;
  grid-template-columns: auto auto auto;

  &__buttons {
    display: flex;
    width: 100%;
  }

  &__field_button {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__new_label {
    display: flex;
    grid-column: span 3;
    column-gap: 12px;
  }

  &__empty {
    width: 100%;
  }

  &__field_label {
    position: relative;
  }
}
