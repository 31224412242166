$env-variable: 'dev';
@use '@common/style/var.scss' as var;

.projectsSelectorContainer {
  position: absolute;
  left: 140px;
  top: 64px;
  max-height: 350px;
  overflow: auto;
  background-color: var(--commonBackgroundColor);
  border-radius: 8px;
  box-shadow: var(--boxShadow);
  height: fit-content;

  .customLink {
    display: flex;
    border-left: 4px solid;
    border-left-color: transparent;

    &:hover {
      border-left-color: var(--highlightColorHover);
      background-color: var(--mainBackgroundColor);
    }

    &:active {
      border-left-color: var(--arrowColorActive);
    }
  }

  .selectedLink {
    border-left-color: var(--highlightColorDefault);
    background-color: var(--mainBackgroundColor);
  }

  .projectContainer {
    display: flex;
    align-items: center;
    padding: 12px;
    column-gap: 12px;

    .projectName {
      display: grid;
      row-gap: 4px;
    }

    .projectKey {
      font-size: 12px;
      color: var(--white_50);
    }
  }
}
