$env-variable: 'dev';
@use '@common/style/var' as var;

@mixin titleContainer {
  color: var(--white);
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}

.dataRefreshSettings {
  margin-right: 20px;
  cursor: pointer;
}

.locationContainer {
  display: flex;
  width: 100%;
  background: var(--lightBg);
  color: var(--white);
  border-radius: 6px;
  overflow: hidden;
  border: unset;

  .mapContainer {
    width: 60%;
    padding: 24px 32px 30px 24px;
    position: relative;

    @media only screen and (min-width: 1440px) {
      width: 70%;
    }

    @media only screen and (min-width: 1920px) {
      width: 80%;
    }

    .mapTitleContainer {
      width: max-content;
      display: flex;
      justify-content: space-between;

      .titleContainer {
        @include titleContainer;
      }
    }

    .legendContainer {
      position: absolute;
      top: 640px;
      left: 90px;
      padding: 10px;

      .legendItemContainer {
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;

        .textItem {
          margin-left: 6px;
          color: var(--white);
          font-size: 14px;
        }
      }
    }

    .map {
      margin-top: 40px;
    }
  }

  .statsContainer {
    padding: 24px 17px 0 24px;
    border-left: 1px solid rgba(175, 190, 216, 0.2);
    width: 40%;
    @media only screen and (min-width: 1440px) {
      width: 30%;
    }
    @media only screen and (min-width: 1920px) {
      width: 20%;
    }

    .titleContainer {
      @include titleContainer;
    }

    .statsListContainer {
      margin-top: 20px;
      height: 700px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 15px;
    }
  }
}
