$env-variable: 'dev';
@use '@common/style/var.scss' as var;

%inputButton {
  color: var(--highlightColorDefault);
  cursor: pointer;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-size: 15px;
}

.formContent {
  display: grid;
  row-gap: 64px;
}

.buttonContainer {
  display: flex;
  width: 315px;
}

.switcherContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switcherField {
  display: grid;
}

.switcher {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.labelSwitcher {
  font-size: 12px;
}

.inputWithButton {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.inputButton {
  @extend %inputButton;
  white-space: nowrap;
}

.resetButton {
  @extend %inputButton;
  border-radius: 8px;
  position: static;
  font-weight: 500;
  padding: 4px 8px;

  &:active {
    background-color: var(--ghostButtonActive);
  }
}
