$env-variable: 'dev';
@use '@common/style/var.scss' as var;

html,
body {
  height: 100%;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--white);
}

body {
  margin: 0;
  font-family: Gordita, serif;
  background-color: var(--mainBackgroundColor);
  color: var(--white);

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--inputTextHoverBgColor);
    outline: unset;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 16px;
    width: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}

textarea {
  font-family: monospace;
}

@font-face {
  font-family: Gordita;
  src: url('/src/fonts/Gordita-Regular.eot');
  src:
    url('/src/fonts/Gordita-Regular.eot?#iefix') format('embedded-opentype'),
    url('/src/fonts/Gordita-Regular.woff2') format('woff2'),
    url('/src/fonts/Gordita-Regular.woff') format('woff'),
    url('/src/fonts/Gordita-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: Gordita;
  src:
    local(Gordita),
    url('/src/fonts/Gordita-Medium.ttf') format('truetype'),
    url('/src/fonts/Gordita-Medium.woff') format('woff2'),
    url('/src/fonts/Gordita-Medium.woff2') format('woff2');
  font-weight: 600;
}

@font-face {
  font-family: Gordita;
  src:
    local(Gordita),
    url('/src/fonts/Gordita-Bold.ttf') format('truetype');
  font-weight: 900;
}
