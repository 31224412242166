$env-variable: 'dev';
@use '@common/style/var' as var;

.exportContainer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.calendarContainer {
  position: relative;
  height: 370px;
  width: 480px;
  background-color: var(--mainBackgroundColor);
  padding: 0 10px;
  border-radius: 10px;

  div {
    color: var(--white);
  }
}

.popUp {
  width: 250px;
  position: absolute;
  box-sizing: border-box;
  padding: 28px;
  color: var(--white);
  background-color: var(--nightShade);
  box-shadow: var(--boxShadow);
  border-radius: 4px;
  top: 20px;
  right: 0;
  z-index: 10;

  .popUpRow {
    text-decoration: underline;
    padding: 5px 0;
    cursor: pointer;
  }
}
