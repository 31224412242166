$env-variable: 'dev';
@use '@common/style/var.scss' as var;

.iconContainer {
  cursor: pointer;
  width: 10px;
  height: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: var(--arrowColorDefault);

  &.sorted {
    filter: var(--arrowColorActive);
  }

  &.rotate {
    transform: rotate(180deg);
  }
}
