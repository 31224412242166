$env-variable: 'dev';
.projectSearchContainer {
  position: relative;

  input {
    padding-left: 48px;
  }
}

.searchIconContainer {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;

  img {
    opacity: 0.5;
  }
}
