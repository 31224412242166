$env-variable: 'dev';
.overridePage {
  display: grid;
  row-gap: 16px;
  width: 650px;
}

.buttons {
  width: 250px;
}

.overrideForm {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.headerBtn {
  display: flex;
  column-gap: 12px;
}
