$env-variable: 'dev';
@use '@common/style/var.scss' as var;

.poolHeader {
  display: flex;
  align-items: center;
  row-gap: 8px;
  column-gap: 8px;

  &__actions {
    display: flex;
    margin-left: auto;
    column-gap: 12px;
  }

  .item {
    color: var(--white)_80;
  }
}
