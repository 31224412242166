$env-variable: 'dev';
.itemContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
  column-gap: 20px;
  white-space: pre;

  .nameItem {
    color: #b2b9c4;
    font-size: 14px;
  }
}
