$env-variable: 'dev';
@use '@common/style/var' as var;

.commonInput {
  outline: 0;
  border: 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  line-height: 24px;
  color: var(--white);
  background-color: var(--inputTextDefaultBgColor);

  &::placeholder {
    color: var(--white)_20;
  }

  :disabled {
    background-color: var(--inputTextDisabledBgColor);
  }
}
