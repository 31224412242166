$env-variable: 'dev';
@use '@common/style/var' as var;
@use '@common/style/typography' as *;

.tableContainer {
  background-color: var(--tableColor);
  border-radius: 12px;
  padding: 24px;
  display: grid;
  row-gap: 20px;
  max-width: 100%;
  width: 100%;
  min-width: max-content;

  .styledTable {
    border-collapse: collapse;
    word-break: break-word;
    table-layout: auto;
    width: 100%;
    max-width: 100%;
    @include typography;
  }
}

.tdJson {
  font-family: monospace;
}

.buttonsColumn {
  display: flex;
  justify-content: end;
  column-gap: 8px;
  visibility: hidden;
}

.tdButton {
  position: absolute;
  top: 4px;
  right: 0;
  visibility: hidden;
}

.tHead {
  width: 100%;
  word-break: break-word;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border: none;

  .headersTR {
    color: var(--white);
    border-bottom: 1px solid var(--white)_20;
  }
}

.th {
  .thContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
    padding: 8px 8px 8px 0;
    width: max-content;
    column-gap: 12px;
    max-width: 150px;

    .sortContainer {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.tBody {
  color: var(--white);

  &:before {
    line-height: 12px;
    content: '-';
    color: transparent;
    display: block;
  }

  .tr {
    vertical-align: text-bottom;
    word-break: break-all;

    &:hover {
      background: var(--nightShade);

      .buttonsColumn {
        visibility: visible;
      }

      .tdButton {
        visibility: visible;
      }
    }

    .td {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--white)_80;
      padding: 8px 12px 8px 4px;
      position: relative;
      align-content: flex-start;
      cursor: pointer;
      word-wrap: break-word;
      word-break: break-all;
      max-width: 250px;

      &.number {
        padding-right: 40px;
      }

      h5 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        word-break: keep-all;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        word-break: keep-all;
      }
    }
  }
}

.dateItem {
  font-size: 15px;
}

.tableTagContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 200px;
  gap: 6px;
}

.tableTagName {
  margin-right: 4px;
  column-gap: 6px;
  row-gap: 8px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.time {
  color: var(--white)_30;
}

.tableTagCountries {
  color: var(--white);
  background-color: var(--tableBackgroundColor);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  height: 24px;
  min-width: 33px;
  padding: 4px 8px;
}
