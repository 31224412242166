$env-variable: 'dev';
@use '@common/style/var' as var;

.nameItemMetric {
  display: flex;
  align-items: center;
  margin-right: 5px;
  cursor: pointer;
  color: inherit;
}

.legendContainer {
  overflow: auto;
  max-height: 260px;
  width: 150px;
  padding-right: 10px;
  margin-left: 28%;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 9px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background: #252b33;
    opacity: 0.6;
  }

  &:hover::-webkit-scrollbar-thumb {
    opacity: 1;
  }

  &:hover::-webkit-scrollbar {
    width: 8px;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .iconContainer {
    display: flex;

    .zoomContainer {
      cursor: pointer;
      margin-left: 16px;
    }
  }
}

.containerNoFound {
  position: relative;
  top: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #b2b9c4;
    width: 98px;
    margin-left: 10px;
  }
}

.viewPort {
  width: 100%;
}

.tooltipContainer {
  background-color: #1a1c23;
  border-radius: 6px;
  padding: 15px;
  font-size: 12px;
  min-width: 160px;
  max-height: 300px;
  overflow-y: auto;
  pointer-events: auto;
  z-index: 2;

  p {
    margin-top: 0;
    text-align: center;
    color: var(--white);
  }

  svg {
    margin-right: 5px;
  }

  .itemTooltip {
    display: flex;
    margin-bottom: 2px;
    justify-content: space-between;

    .name {
      display: flex;
      align-items: center;
    }

    .value {
      margin-left: 5px;
    }
  }
}

.titleLegend {
  width: 185px;
  display: flex;
  margin-left: 33px;
  justify-content: start;
  color: var(--white);
}
